<!-- 商家管理 -->
<template>
  <div>
    <div style="margin-bottom: 5px">
      <el-input
        v-model="title"
        placeholder="请输入店铺名"
        suffix-icon="el-icon-search"
        style="width: 200px"
        @keyup.enter.native="loadPost"
      ></el-input>

      <el-button type="primary" style="margin-left: 5px" @click="loadPost"
        >查询</el-button
      >
      <el-button type="success" @click="resetParam">重置</el-button>

      <el-button type="primary" style="margin-left: 5px" @click="add"
        >新增</el-button
      >
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
      border
    >
      <el-table-column prop="id" label="ID" width="80"> </el-table-column>
      <el-table-column prop="number" label="店铺编号" width="150">
      </el-table-column>
      <el-table-column prop="title" label="店铺名称" width="140">
      </el-table-column>
      <el-table-column prop="logo" label="店铺图片" width="120">
        <template slot-scope="scoope">
          <img
            style="width: 50px; height: 50px"
            :src="$httpUrl + '/image/' + scoope.row.logo"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column prop="address" label="地址" width="80">
      </el-table-column>
      <el-table-column prop="phone" label="电话" width="140"> </el-table-column>
      <el-table-column prop="context" label="店铺简介" width="180">
      </el-table-column>
      <el-table-column prop="d_ids" label="剧本集合" width="180">
      </el-table-column>
      <el-table-column prop="operate" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="success" @click="mod(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="del(scope.row.id)"
            style="margin-left: 5px"
          >
            <el-button slot="reference" size="small" type="danger"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 20, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      :show-close="false"
      width="30%"
      center
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="店铺编号" prop="number">
          <el-col :span="20">
            <el-input v-model="form.number"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="店铺名称" prop="title">
          <el-col :span="20">
            <el-input v-model="form.title"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="店铺图片" prop="logo">
          <el-col :span="20">
            <imgUploader
              :iga="form.logo"
              ref="pic2"
              :width="200"
              :height="200"
              :limit="1"
              @change="img()"
            >
            </imgUploader>
          </el-col>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-col :span="20">
            <el-input v-model="form.phone"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-col :span="20">
            <el-input v-model="form.address"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="店铺简介" prop="context">
          <el-col :span="20">
            <el-input v-model="form.context"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quxiao()">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import imgUploader from "../imgUploader/imgUploader.vue";
export default {
  components: { imgUploader },
  name: "AdminManage",
  data() {
    return {
      tableData: [
        // {
        //   id: "1",
        //   number: "00001",
        //   title: "武装力量了",
        //   address: "浙江温州",
        //   context: "zcxzxczasdsadasdcx",
        //   phone: "15941564815",
        //   logo: "1677214536390.jpeg",
        //   d_ids: "1, 5, 12",
        // },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      title: "",
      centerDialogVisible: false,
      form: {
        id: "",
        title: "",
        phone: "",
        number: "",
        context: "",
        logo: [],
        address: "",
      },
      rules: {
        number: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { min: 3, max: 8, message: "长度在 3 到 8 个字符", trigger: "blur" },
          // { validator: checkDuplicate, trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入店铺名称", trigger: "blur" }],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        logo: [{ required: true, message: "请添加店铺logo", trigger: "blur" }],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
      },
    };
  },
  methods: {
    quxiao() {
      (this.form = {
        id: "",
        title: "",
        phone: "",
        number: "",
        context: "",
        logo: [],
        address: "",
      }),
        console.log("123");

      this.centerDialogVisible = false;
    },
    img() {
      // let vm = this;
      // let b = vm.$refs.pic2.imgUrl;
      // this.form.logo = b;
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    del(id) {
      console.log(id);
      this.$axios
        .get(this.$httpUrl + "/shop/remove/" + id)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadPost();
          } else {
            this.$message({
              message: "删除失败！",
              type: "error",
            });
          }
        });
    },
    mod(row) {
      console.log(row);
      (this.form.id = row.id),
        (this.form.title = row.title),
        (this.form.phone = row.phone),
        (this.form.number = row.number),
        (this.form.context = row.context),
        (this.form.logo = []),
        (this.form.address = row.address),
        //赋值到表单
        this.form.logo.push({
          base64: this.$httpUrl + "/image/" + row.logo,
        });
      console.log(this.form);
      this.$nextTick(() => {
        this.centerDialogVisible = true;
      });
    },
    add() {
      this.form = {};
      console.log(this.form.logo);
      this.centerDialogVisible = true;
    },
    doSave() {
      this.$axios
        .post(this.$httpUrl + "/user/save", this.form)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.centerDialogVisible = false;
            this.loadPost();
          } else {
            this.$message({
              message: "操作失败！",
              type: "error",
            });
          }
        });
    },
    doMod() {
      this.$axios
        .post(this.$httpUrl + "/user/update", this.form)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.centerDialogVisible = false;
            this.loadPost();
            this.resetForm();
          } else {
            this.$message({
              message: "操作失败！",
              type: "error",
            });
          }
        });
    },
    save() {
      if (!this.form.id > 0) {
        let vm = this;
        console.log(vm.$refs.pic2.imgUrl);
        let b = vm.$refs.pic2.imgUrl;
        this.form.logo = b;
      }
      console.log("--------------------");
      console.log(this.form.logo);
      if (this.form.logo.length < 1) {
        this.$message({
          message: "图片为空！",
          type: "error",
        });
        return;
      }

      if (
        this.form.number == undefined ||
        this.form.number == "" ||
        this.form.title == undefined ||
        this.form.title == "" ||
        this.form.phone == undefined ||
        this.form.phone == "" ||
        this.form.address == undefined ||
        this.form.address == ""
      ) {
        this.$message({
          message: "有为空的必填项！",
          type: "error",
        });
        return;
      }

      console.log(this.form.logo);
      let a = {
        id: this.form.id,
        title: this.form.title,
        phone: this.form.phone,
        number: this.form.number,
        context: this.form.context,
        address: this.form.address,
        logo: this.form.logo[0].base64,
      };
      console.log(a);
      this.$axios
        .post(this.$httpUrl + "/shop/addUpdate", a)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);

          if (res.code == 200) {
            this.loadPost();
            this.$message({
              message: "操作成功！",
              type: "success",
            });

            this.centerDialogVisible = false;
          } else {
            this.$message({
              message: "添加失败！",
              type: "warning",
            });
          }
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageNum = 1;
      this.pageSize = val;
      this.loadPost();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.loadPost();
    },
    loadGet() {
      this.$axios
        .get(this.$httpUrl + "/user/list")
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
        });
    },
    resetParam() {
      this.name = "";
      this.sex = "";
    },
    loadPost() {
      this.tableData = [];
      this.$axios
        .post(this.$httpUrl + "/shop/pagelist", {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          id: "",
          param: {
            title: this.title,
          },
          role: this.$store.getters.getMenu.role,
        })
        .then((res) => res.data)
        .then((res) => {
          console.log(res.data);
          // {
          //     id:'as',
          //     no:'dsada',
          //     name:'sasd',
          //     address:'sadsad',
          //     introduction:'zcxzxczcx',
          //     phone:'xczczxccz',
          // }
          if (res.code == 200) {
            this.tableData = res.data;
          }
        });
    },
  },
  beforeMount() {
    this.loadPost();
    var a = [1, 2, 3, 4];
    console.log(a.splice(1, 1));
    console.log(a);
  },
};
</script>

<style scoped>
</style>